import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination]);
Swiper.use([Autoplay]);

let swiperdirectory = new Swiper(".directory__slider", {
  spaceBetween: 20,
  loop: false,
  grabCursor: true,
  pagination: {
    el: ".slider-directory__pagination",
    clickable: true,
  },
  slidesPerView: 3,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetweenSlides: 20,
    },
    400: {
      slidesPerView: 1,
      spaceBetweenSlides: 20,
    },
    // when window width is <= 499px
    855: {
      slidesPerView: 2,
      spaceBetweenSlides: 20,
    },
    // when window width is <= 999px
    1200: {
      slidesPerView: 2,
      spaceBetweenSlides: 20,
    },

    1216: {
      slidesPerView: 3,
      spaceBetweenSlides: 20,
    },
  },
});

let swiperreviews = new Swiper(".reviews__slider", {
  spaceBetween: 20,
  loop: false,
  grabCursor: true,
  pagination: {
    el: ".slider-reviews__pagination",
    clickable: true,
  },
  slidesPerView: 3,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetweenSlides: 20,
    },
    400: {
      slidesPerView: 1,
      spaceBetweenSlides: 20,
    },
    // when window width is <= 499px
    855: {
      slidesPerView: 2,
      spaceBetweenSlides: 20,
    },
    // when window width is <= 999px
    1395: {
      slidesPerView: 2,
      spaceBetweenSlides: 20,
    },

    1400: {
      slidesPerView: 3,
      spaceBetweenSlides: 20,
    },
  },
});

let swipervideo = new Swiper(".video__slider", {
  spaceBetween: 32,
  loop: false,
  grabCursor: true,
  pagination: {
    el: ".slider-video__pagination",
    clickable: true,
  },
  slidesPerView: 3,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetweenSlides: 32,
    },
    400: {
      slidesPerView: 1,
      spaceBetweenSlides: 32,
    },
    // when window width is <= 499px
    855: {
      slidesPerView: 2,
      spaceBetweenSlides: 32,
    },
    // when window width is <= 999px
    1395: {
      slidesPerView: 2,
      spaceBetweenSlides: 32,
    },

    1400: {
      slidesPerView: 2,
      spaceBetweenSlides: 32,
    },

    1402: {
      slidesPerView: 3,
      spaceBetweenSlides: 32,
    },
  },
});

let swiperproject = new Swiper(".project__slider", {
  spaceBetween: 20,
  loop: false,
  grabCursor: true,
  pagination: {
    el: ".slider-project__pagination",
    clickable: true,
  },
  slidesPerView: 1,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
});

let swipercertificate = new Swiper(".certificate__slider", {
  spaceBetween: 33,
  loop: false,
  grabCursor: true,
  slidesPerView: 5,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetweenSlides: 32,
    },
    400: {
      slidesPerView: 1,
      spaceBetweenSlides: 32,
    },
    // when window width is <= 499px
    855: {
      slidesPerView: 2,
      spaceBetweenSlides: 32,
    },

    1215: {
      slidesPerView: 3,
      spaceBetweenSlides: 32,
    },

    1217: {
      slidesPerView: 5,
      spaceBetweenSlides: 33,
    },
  },
});
